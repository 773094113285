/* global Rails */

import 'bootstrap/js/src/alert'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/tab'
import 'bootstrap/js/src/button'

import Cookies from 'js-cookie'
window.Cookies = Cookies

const App = {}
window.App = App

App.newClose = function () {
  $('.guest-banner').removeClass('visible')
  const c = $('.guest-banner').data('cta')
  const ccount = parseInt($('.guest-banner').data('ctaCount'), 10)

  if (Number.isNaN(ccount)) {
    Cookies.set(c, true, { expires: 15, path: '/' })
  } else {
    const val = parseInt(Cookies.get(c), 10)
    if (Number.isNaN(val)) {
      Cookies.set(c, 1, { expires: 15, path: '/' })
    } else {
      if (val + 1 === ccount) {
        Cookies.set(c, true, { expires: 15, path: '/' })
      } else {
        Cookies.set(c, val + 1, { expires: 15, path: '/' })
      }
    }
  }
  setTimeout(() => {
    $('.guest-banner').remove()
  }, 250)
}
;(function () {
  // Executed one time at first page loading
  this.start = function () {
    App.project.start()
    App.selectFile.start()
  }

  // Executed on each page load
  this.init = function () {
    const self = this
    self.selectDropdown.init()
    self.selectFile.init()
    self.notifications.init()
    self.formHelper.init()
    self.auth.init()
    self.accordion.init()
    self.modal.init()
    self.orders.init()
    self.menuOverflow.init()
    self.charactersCounter.init()

    // Views
    self.userConnection.init()

    // ACCEPT COOKIE EVENT
    if ($('.accept-cookies').length > 0) {
      setTimeout(() => {
        $('.accept-cookies').fadeIn()
      }, 2000)
    }
    $('.accept-cookies-btn').click(function (event) {
      $(this).parent().remove()
      Rails.ajax({
        url: '/accept-cookies',
        type: 'POST'
      })
    })

    // COPY-TO
    $('body').on('click', '.copy-to', function (event) {
      event.preventDefault()
      $($(this).data('target')).val($(this).data('text'))
    })

    // ALLOW SUBMIT VIA TEXTAREA
    $('body').on('keydown', 'textarea:not(#chat_content, .js-not-auto-submit)', function (e) {
      if (e.keyCode === 13 && (e.metaKey || e.ctrlKey)) {
        Rails.fire(this.form, 'submit')
      }
    })

    $('.search-toggler').click(function (e) {
      e.preventDefault()
      $(this).toggleClass('hamburger-open')
      $('body').toggleClass('sidebar-closed')
    })

    $('.inbox-navigation-toggler').click(function () {
      $(this).toggleClass('hamburger-open')
      $('.inbox-navigation').toggleClass('active')
    })

    $('body').click(event => {
      if ($('.inbox-navigation').hasClass('active') && $(event.target).parents('.inbox-navigation').length <= 0) {
        $('.inbox-navigation-toggler').click()
      }
    })

    $('.inbox-navigation .messages-search input').focus(() => {
      if (!$('.inbox-navigation').hasClass('active')) {
        $('.inbox-navigation-toggler').click()
      }
    })

    $(document).on('click', '.subcategory-selector', function (event) {
      event.preventDefault()
      if ($('.subcategory-selector.selected').length >= 5 && !$(this).hasClass('selected')) {
        App.notifications.flash('info', 'Vous ne pouvez pas selectionner plus de 5 compétences.')
      } else {
        $(this).toggleClass('selected')
      }
    })

    $(document).on('click', '.new-project-with-categories', event => {
      event.preventDefault()
      const ids = $.map($('.subcategory-selector.selected'), (element, index) => {
        return $(element).attr('id')
      })
      window.location = '/projects/new?categories=' + ids.join(',')
    })

    $(document).on('keyup', 'input.search', function () {
      $.each($(this).parent().find('.menu').children('.header'), (index, element) => {
        const header = $(element).text()
        if (
          $(element)
            .parent()
            .children('.item[data-header="' + header + '"]')
            .not('.filtered').length === 0
        ) {
          $(element).addClass('hidden')
        } else {
          $(element).removeClass('hidden')
        }
      })
    })

    $('.new-close').click(App.newClose)

    if ($('.guest-client-banner').length > 0) {
      $(document).scroll(function (event) {
        const y = $(this).scrollTop()
        let yBottomBanner = 300
        const banner = $('.banner').first()
        if (banner.length > 0) {
          yBottomBanner += banner.offset().top + banner.outerHeight()
        }
        if (y > yBottomBanner) {
          $('.guest-client-banner').addClass('visible')
        } else {
          $('.guest-client-banner').removeClass('visible')
        }
      })
    }

    if ($('.guest-presta-banner').length > 0) {
      setTimeout(() => {
        $('.guest-presta-banner').addClass('visible')
      }, 5000)
    }

    // Open automatically
    $('*[data-open-automatically]').each(function () {
      this.click()
    })
  }
}).apply(App)

document.addEventListener('DOMContentLoaded', () => {
  App.start()
})

document.addEventListener('turbo:load', () => {
  App.init()
})

document.addEventListener('turbo:frame-render', () => {
  App.notifications.init()
})
